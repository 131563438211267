import { FC, PropsWithChildren, Children, forwardRef, ReactNode, RefObject } from "react";
import styles from "./Select.module.scss";
import useTranslation from "next-translate/useTranslation";

type SelectOptionsMenuProps = {
  children: ReactNode,
  className: string,
  NoOptionsMessageText?: string,
  value: string,
  editMode: boolean,
  inThesis?: boolean,
  id?: string,
};

const SelectOptionsMenu: FC<PropsWithChildren<SelectOptionsMenuProps>> = forwardRef(
  (props, ref) => {
    const { t } = useTranslation("common");
    const { id, children, className, NoOptionsMessageText = t("react_select.no_option_message"), value, editMode = false, inThesis } = props;

    const NoOptionsMessage = () => {
      return (
        <div className={styles.option_message}>
          {inThesis ? t("react_select.option_message") : NoOptionsMessageText}
        </div>
      );
    };

    const filteredChildren = Children.toArray(children).filter(
      (child: JSX.Element) => !value || child.props.children?.toLowerCase().includes(String(value).toLowerCase())
    );

    filteredChildren.sort((a: JSX.Element, b: JSX.Element) => {
      return a.props.children?.indexOf(value.toLowerCase()) - b.props.children?.indexOf(value.toLowerCase());
    });

    return (
      <div
        className={className}
        id={id}
        ref={ref as RefObject<HTMLDivElement>}
      >
        <ul className="list-unstyled mb-0">
          {
            editMode ?
              (
                filteredChildren.length === 0 ?
                  <NoOptionsMessage />
                  :
                  filteredChildren
              )
              :
              (Children.toArray(children))
          }
        </ul>
      </div>
    );
  },
);

SelectOptionsMenu.displayName = "SelectOptionsMenu";

export default SelectOptionsMenu;
