import { SyntheticEvent, useEffect, Dispatch, SetStateAction, FC, PropsWithChildren } from "react";
import styles from "./Select.module.scss";
import clsx from "clsx";
import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import { IDictionaryItem } from "@/lib/services/dictionary.service";
import { FieldName, FieldValues, SetFieldValue, SetValueConfig } from "react-hook-form";
import SelectInput from "./SelectInput";
import SelectOptionsMenu from "./SelectOptionsMenu";

type FormSelectProps = {
  id?: string,
  name: string,
  options: IDictionaryItem[] | null | ({ label: string; value: string; } | undefined)[],
  setValue?: (name: FieldName<FieldValues>, value: SetFieldValue<FieldValues>, config?: SetValueConfig) => void;
  defaultValue?: IDictionaryItem | undefined,
  placeholder?: string,
  NoOptionsMessageText?: string,
  disabled?: boolean,
  isInvalid?: boolean,
  clearErrors?: (name: string) => void,
  watch?: (names: (string), value?: string) => string,
  setThesisText?: (value: string) => void;
  inThesis?: boolean;
  className?: string;
  onChange?: (value: IDictionaryItem) => void;
};

const FormSelect: FC<PropsWithChildren<FormSelectProps>> = (props): JSX.Element => {
  const { id, options, className, inThesis, defaultValue, name, setValue, clearErrors, NoOptionsMessageText, placeholder, isInvalid = false, disabled = false, watch, setThesisText, onChange } = props;
  const emptyDictionaryItem: IDictionaryItem = { label: "", value: "" };
  const [currentValue, setCurrentValue] = useState(defaultValue || emptyDictionaryItem);
  const [editMode, setEditMode] = useState(false);
  const [focus, setFocus] = useState(false);

  if (watch) {
    const watchSelect = watch(name, defaultValue?.value as string);

    useEffect(() => {
      if (options) {
        const value = (options as ({ label: string; value: string; } | undefined)[])
          .find((item): item is { label: string; value: string; } => item !== undefined && item.value === watchSelect);

        setCurrentValue(value as IDictionaryItem);
      }
    }, [watchSelect, options]);
  }

  return (
    <Dropdown
      id={`${id}_wrapper`}
      onToggle={(toggle: boolean, event: SyntheticEvent<Dropdown>): void => {
        if (!toggle) {
          (event.target as HTMLElement).blur();
          setFocus(false);
        } else {
          setFocus(true);
        }
      }}
    >
      <Dropdown.Toggle
        as={SelectInput}
        clearErrors={clearErrors}
        currentValue={currentValue as IDictionaryItem}
        disabled={disabled}
        focus={focus}
        id={id}
        isInvalid={isInvalid}
        name={name}
        placeholder={placeholder}
        setCurrentValue={setCurrentValue as Dispatch<SetStateAction<IDictionaryItem | null>>}
        setEditMode={setEditMode}
        setValue={setValue}
        onInput={(e) => {
          setFocus(true);
          if (setThesisText) setThesisText((e.target as HTMLTextAreaElement).value);
        }}
      />

      {options !== undefined && options && options?.length > 0 && !disabled && (
        <Dropdown.Menu
          NoOptionsMessageText={NoOptionsMessageText}
          as={SelectOptionsMenu}
          className={clsx(styles.dropdownMenu, focus && "show", className)}
          editMode={editMode}
          id={`${id}_menu`}
          inThesis={inThesis}
          value={currentValue?.label || ""}
        >
          {options.map((item: any, ind) => {
            return (
              <Dropdown.Item
                className={clsx(styles.dropdownItem, currentValue?.value === item?.value ? styles.active : "")}
                eventKey={item?.value}
                id={`selectItem-${(String(item.value).replace(/\s+/g, "_"))}`}
                key={ind}
                onSelect={(eventKey) => {
                  let currentElem: IDictionaryItem | { label: string; value: string } | any;

                  for (const item of options) {
                    if (item && String(item.value) === eventKey) {
                      currentElem = item;
                      break;
                    }
                  }

                  if (currentElem) {
                    const labelValue = currentElem.label;
                    eventKey && setCurrentValue({ value: eventKey, label: labelValue });
                  }

                  setValue && setValue(name, eventKey);
                  clearErrors && clearErrors(name);
                  onChange && onChange(currentElem);
                }}
              >
                {item?.label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default FormSelect;
