import { FC, FormEvent, ForwardedRef, PropsWithChildren, MouseEvent as ReactMouseEvent, Dispatch, forwardRef, ReactNode, SetStateAction } from "react";
import { IDictionaryItem } from "@/lib/services/dictionary.service";
import { FieldName, FieldValues, SetFieldValue, SetValueConfig } from "react-hook-form";
import { Form } from "react-bootstrap";
import styles from "@/components/_Common/Form/Select/Select.module.scss";
import ArrowDownIcon from "@/public/images/symbols/arrow_down.svg";
import ArrowUpIcon from "@/public/images/symbols/arrow_up.svg";

type SelectInputProps = {
  name: string,
  children: ReactNode,
  onClick: (event: ReactMouseEvent<HTMLInputElement, MouseEvent>) => void,
  currentValue: IDictionaryItem,
  placeholder?: string,
  isInvalid: boolean,
  setCurrentValue: Dispatch<SetStateAction<IDictionaryItem | null>>,
  setValue?: (name: FieldName<FieldValues>, value: SetFieldValue<FieldValues>, config?: SetValueConfig) => void,
  disabled?: boolean,
  clearErrors?: (name: string) => void,
  setEditMode: Dispatch<SetStateAction<boolean | null>>,
  onInput: (name: FormEvent) => void,
  onFocus: () => void,
  focus?: boolean;
  id?: string;
};

const SelectInput: FC<PropsWithChildren<SelectInputProps>> = forwardRef((props, ref) => {
  const {
    name,
    onClick,
    currentValue,
    placeholder,
    isInvalid,
    setCurrentValue,
    setValue,
    disabled = false,
    setEditMode,
    onInput,
    onFocus,
    focus,
    id,
  } = props;

  const modals = ["Conditions", "Thesis", "SpecialityExam", "ForeignLanguageExamModal", "Scientific", "ForeignLanguageDateExamModal", "ForeignLanguageDate"];

  return (
    <>
      <Form.Control
        className={modals.includes(name) ? styles.selectInputThesis : styles.selectInput}
        disabled={disabled}
        id={id}
        isInvalid={isInvalid}
        placeholder={placeholder}
        ref={ref as ForwardedRef<HTMLInputElement>}
        type="text"
        value={currentValue ? currentValue?.label : ""}
        onClick={onClick}
        onFocus={onFocus}
        onInput={onInput}
        onChange={(e) => {
          setEditMode(true);
          setCurrentValue({ value: "", label: e.target.value });
          setValue && setValue(name, "");
        }}
      />
      <div className={styles.dropdownIconWrap} onClick={onClick}>
        {focus ? <ArrowUpIcon className={styles.dropdownIconUp} /> : <ArrowDownIcon className={styles.dropdownIcon} />}
      </div>
    </>
  );
});

SelectInput.displayName = "SelectInput";

export default SelectInput;
